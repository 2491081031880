import { useTranslation } from "next-i18next";
import SearchProductCard from "@/components/Helpers/SearchProductCard";

const SuggestProducts = ({ products, clearSearch }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("common");

  return (
    <>
      <h3 className="mb-2 text-sm font-semibold text-red">
        {language == "en" ? "Matching Products" : "المنتجات المشابهة"}
      </h3>
      <div className="grid grid-cols-3 gap-2 max-[991px]:grid-cols-2">
        {products?.slice(0, 6)?.map((product, key) => {
          return (
            <SearchProductCard
              key={`search-product-card-${key}`}
              product={product}
              language={language}
              t={t}
              clearSearch={clearSearch}
            />
          );
        })}
      </div>
    </>
  );
};

export default SuggestProducts;
