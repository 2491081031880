import FinalSearchSuggestion from "./final/FinalSearchSuggestion";
import InitialSearchSuggestion from "./initial/InitialSearchSuggestion";

const SearchTermsBox = ({
  openSearchTermsRef,
  inputValue,
  products,
  clearSearch,
  redirectToSearch,
  suggestions,
  handleSearch,
}) => {
  return (
    <div
      ref={openSearchTermsRef}
      className={`absolute inset-y-10 z-[999]  w-full start-0`}
    >
      <div className=" bg-white rounded-lg shadow-lg  max-h-[70vh] overflow-y-auto p-2 mx-2 red-scrollbar">
        {(!inputValue || !products?.length) && (
          <InitialSearchSuggestion
            clearSearch={clearSearch}
            redirectToSearch={redirectToSearch}
          />
        )}

        {inputValue && !!products?.length && (
          <FinalSearchSuggestion
            clearSearch={clearSearch}
            products={products}
            inputValue={inputValue}
            redirectToSearch={redirectToSearch}
            suggestions={suggestions}
            handleSearch={handleSearch}
          />
        )}
      </div>
    </div>
  );
};

export default SearchTermsBox;
