// import { IS_TYPESENSE_SEARCH } from "@/utils/typesense/config";
import { useRouter } from "next/router";
import SuggestedCategories from "./SuggestedCategories";
import SuggestedBrands from "./SuggestedBrands";
import { highlightText } from "@/utils/global";
import { useEffect, useState } from "react";
import useFilteredCategories from "@/hooks/algolia/useFilteredCategories";
const SuggestWords = ({
  inputValue,
  products,
  handleMouseEnter,
  redirectToSearch,
  suggestions,
  clearSearch,
}) => {
  const { locale } = useRouter();
  const [searchBrands, setSearchBrands] = useState([]);
  const searchCategories = useFilteredCategories(products);

  useEffect(() => {
    if (!products?.length) return;

    // Extract unique brand names
    const brandData = [
      ...new Set(products.map((e) => e?.brand).filter(Boolean)),
    ];
    setSearchBrands(brandData);
  }, [products]);

  return (
    <>
      {suggestions?.length > 0 && (
        <div className="mb-3">
          <h3 className="mb-2 text-sm font-semibold text-red">
            {locale === "ar" ? "الكلمات المقترحة" : "Suggedted words"}
          </h3>{" "}
          <div className="grid grid-cols-1 max-[570px]:grid-cols-3">
            {suggestions?.slice(0, 9)?.map(
              (word, idx) => (
                // !IS_TYPESENSE_SEARCH ? (
                //   <button
                //     onClick={() => {
                //       applySearch(word);
                //     }}
                //     onMouseEnter={() =>
                //       setTimeout(() => {
                //         setSuggestedText(word);
                //       }, 500)
                //     }
                //     key={`suggest_word_term_${word}_${(idx + 1) * 425}`}
                //     className="flex gap-2 p-2 text-xs transition rounded-md hover:bg-slate-200 text-start align-items-center"
                //     dangerouslySetInnerHTML={{
                //       __html: `
                //       <AiOutlineSearch size="14px" className='text-red' />
                //     ${highlightText(inputValue, word) || ""}
                //       `,
                //     }}
                //   />
                // ) : (
                <button
                  dangerouslySetInnerHTML={{
                    __html: `
              <AiOutlineSearch size="14px" className='text-red' />
              ${highlightText(inputValue, word?.query) || ""}

                `,
                  }}
                  onClick={() => {
                    redirectToSearch(word?.query);
                  }}
                  onMouseEnter={() => handleMouseEnter(word?.query)}
                  key={`suggest_word_term_${word?.query}_${(idx + 1) * 425}`}
                  className="flex gap-2 p-2 text-xs transition rounded-md hover:bg-slate-200 text-start align-items-center"
                />
              )
              // )
            )}
          </div>
        </div>
      )}
      {searchBrands?.length > 0 && (
        <SuggestedBrands
          title={locale === "ar" ? "الماركات المقترحة" : "Matching Brands"}
          data={searchBrands}
          redirectToSearch={redirectToSearch}
          searchTerm={inputValue}
        />
      )}
      {searchCategories?.length > 0 && (
        <SuggestedCategories
          title={locale === "ar" ? "الأقسام" : "Categories"}
          data={searchCategories}
          clearSearch={clearSearch}
          searchTerm={inputValue}
        />
      )}
    </>
  );
};

export default SuggestWords;
