import { activeCart } from "@/services/global";
import getDeliveryDate from "@/services/header/getDeliveryDate";
import { setCookie } from "cookies-next";
import Cookies from "js-cookie";

export function formatDate(inputDate, inputDay, lang) {
  let daysOfWeek = [];
  let months = [];
  if (lang.includes("ar")) {
    daysOfWeek = {
      الأحد: "الأحد",
      الإثنين: "الإثنين",
      الثلاثاء: "الثلاثاء",
      الأربعاء: "الأربعاء",
      الخميس: "الخميس",
      الجمعة: "الجمعة",
      السبت: "السبت",
    };
    months = [
      "يناير",
      "فبراير",
      "مارس",
      "أبريل",
      "مايو",
      "يونيو",
      "يوليو",
      "أغسطس",
      "سبتمبر",
      "أكتوبر",
      "نوفمبر",
      "ديسمبر",
    ];
  } else {
    daysOfWeek = {
      الأحد: "Sun",
      الإثنين: "Mon",
      الثلاثاء: "Tue",
      الأربعاء: "Wed",
      الخميس: "Thu",
      الجمعة: "Fri",
      السبت: "Sat",
    };
    months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
  }

  const [, month, day] = inputDate?.split("-")?.map(Number);
  const formattedDate = `${daysOfWeek[inputDay]}، ${day} ${months[month - 1]}`;

  return formattedDate;
}

// Function to check if a given date falls between two dates
export const isDateInRange = (
  startDateString,
  endDateString,
  targetDateString
) => {
  // Convert string dates to Date objects
  const startDate = new Date(startDateString);
  const endDate = new Date(endDateString);
  const targetDate = new Date(targetDateString);

  // Ensure targetDate is between startDate and endDate (inclusive)
  return targetDate >= startDate && targetDate <= endDate;
};

export const getCurrentRiyadhDateTime = () => {
  const currentDate = new Date().toLocaleString("en-US", {
    timeZone: "Asia/Riyadh",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false, // 24-hour time
  }); // output format like: 1/8/2025, 1:22:42 PM

  // Reformatting the output to match 'YYYY-MM-DD HH:mm' like start-date and end-date of slider
  const [date, time] = currentDate.split(", ");
  const [month, day, year] = date.split("/");
  const [hours, minutes, seconds] = time.split(":");
  const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return formattedDateTime;
};
// for search box
const handleEscapeKey = function (
  event,
  onSearch,
  setShowSearchBoxModal,
  setUpdatedProducts
) {
  if (event.key === "Escape") {
    onSearch("");
    setShowSearchBoxModal(false);
    setUpdatedProducts([]);
  }
};

export const handleListenOnkeydownInWindow = (
  searchValue,
  window,
  onSearch,
  setShowSearchBoxModal,
  setUpdatedProducts
) => {
  if (searchValue) {
    window.addEventListener("keydown", (e) =>
      handleEscapeKey(e, onSearch, setShowSearchBoxModal, setUpdatedProducts)
    );
    return () => {
      window.removeEventListener("keydown", (e) =>
        handleEscapeKey(e, onSearch, setShowSearchBoxModal, setUpdatedProducts)
      );
    };
  }
};

export const megaMenuChecker = (item) =>
  item?.include_in_menu === true &&
  item?.show_in_mega_menu === true &&
  item?.product_count > 0;

export const fastDeliveryAndAvailableDatesLogic = async ({
  setTargetProductDate,
  setIsCityHavefastDelivery,
  // setSelectedCity,
  homeJson,
  id,
  // label,
  locale,
  setAvailableDates,
  setRegionId,
}) => {
  Cookies.set("region_id", +id);
  return await import("@/constants/DateCode")
    .then(async ({ default: DateCode }) => {
      await import("@/utils/global")
        .then(async ({ formatDate }) => {
          //////// for update product date
          const cityCode = DateCode[+id];

          const res = id && !isNaN(id) ? await getDeliveryDate(cityCode) : {};
          setTargetProductDate &&
            id &&
            !isNaN(id) &&
            res?.data?.data?.data?.AvailableDates &&
            res?.data?.data?.data?.AvailableDates != undefined &&
            Array.isArray(res?.data?.data?.data?.AvailableDates) &&
            res?.data?.data?.data?.AvailableDates?.length &&
            setTargetProductDate({
              Date:
                formatDate(
                  res?.data?.data?.data?.AvailableDates[0]?.Date || "",
                  res?.data?.data?.data?.AvailableDates[0]?.Day || "",
                  locale
                ) || "",
              Day: res?.data?.data?.data?.AvailableDates[0]?.Day || "",
            });
          id &&
          !isNaN(id) &&
          res?.data?.data?.data?.AvailableDates &&
          res?.data?.data?.data?.AvailableDates != undefined &&
          Array.isArray(res?.data?.data?.data?.AvailableDates) &&
          res?.data?.data?.data?.AvailableDates?.length
            ? setAvailableDates({
                [cityCode]: res?.data?.data?.data?.AvailableDates[0],
              })
            : setAvailableDates({});
        })
        .catch(() => {});
      id && !isNaN(id) && setRegionId(+id);
      //////// for update product fast-delivery
      const fastDeliveryRegionIds =
        Array.isArray(homeJson?.data?.data) &&
        homeJson?.data?.data?.length &&
        homeJson?.data?.data[0]["fast-delivery-region-ids"];

      if (
        Array.isArray(fastDeliveryRegionIds) &&
        fastDeliveryRegionIds?.includes(+id)
      ) {
        setIsCityHavefastDelivery(true);
      } else {
        setIsCityHavefastDelivery(false);
      }
      // setSelectedCity && setSelectedCity(label);
    })
    .catch(() => {});
};

const normalizeSrc = (src) => {
  return src.startsWith("/") ? src.slice(1) : src;
};

export function cloudflareLoader({ src, width, quality }) {
  const params = [`width=${width}`, "format=auto", "quality=75"];
  if (quality) {
    params.push(`quality=${quality}`);
  }
  const paramsString = params.join(",");
  return `${
    process.env.NEXT_PUBLIC_IMAGE_BASEURL
  }/cdn-cgi/image/${paramsString}/${normalizeSrc(src)}`;
}

export const handlePagination = ({
  entries,
  pageIndex,
  MoreProductsData,
  cache,
  url_cach,
  setPageIndex,
  handleTriggerMoreProducts,
  setUpdatedProducts,
  MoreProductsDataIsMutating,
  word,
}) => {
  console.log("paginate", {
    isIntersecting: entries[0].isIntersecting,
    MoreProductsDataIsMutating,
    word,
  });
  if (entries[0].isIntersecting && !MoreProductsDataIsMutating && !!word) {
    if (!(pageIndex > MoreProductsData?.pages) && !cache.get(url_cach)) {
      setPageIndex((prev) => prev + 1);
      handleTriggerMoreProducts(pageIndex);
    } else {
      if (cache) {
        setUpdatedProducts((prev) => [
          ...prev,
          ...(cache.get(url_cach)?.data?.products || []),
        ]);
        setPageIndex((prev) => prev + 1);
      }
    }
  }
};

export const convertToGMTPlus3 = (date) => {
  // Create a new date object in GMT+0 (UTC)
  const gmtDate = new Date(
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    )
  );
  const gmtPlus3Date = new Date(gmtDate.getTime() + 3 * 60 * 60 * 1000);
  return { gmtDate: gmtDate?.getTime(), gmtPlus3Date: gmtPlus3Date?.getTime() };
};

/**
 * Decodes a JWT token
 * @param {string} token - The JWT token to decode
 * @param {boolean} [isServerSide=true] - Whether the function is running on the server side
 * @returns {Object|null} The decoded payload or null if invalid
 */
export function decodeJwt(token, isServerSide = true) {
  if (typeof token !== "string" || token.split(".").length !== 3) {
    console.error("Invalid JWT token format");
    return null;
  }

  try {
    const [, payloadBase64] = token.split(".");
    const base64 = payloadBase64.replace(/-/g, "+").replace(/_/g, "/");

    let decodedPayload;
    if (isServerSide) {
      decodedPayload = Buffer.from(base64, "base64").toString("utf-8");
    } else {
      decodedPayload = atob(base64);
    }

    return JSON.parse(decodedPayload);
  } catch (error) {
    console.error("Error decoding JWT:", error);
    return null;
  }
}

export function getObjectsInDataByKey(arr, key, searchValue) {
  let result = [];
  function handleData(arr) {
    arr?.forEach((item) => {
      if (item?.[key] == searchValue) {
        result.push(item);
      }

      if (
        Array.isArray(item?.children_data) &&
        item?.children_data &&
        item?.children_data?.length > 0
      ) {
        handleData(item?.children_data);
      }
    });
  }
  handleData(arr);

  return result;
}

export function unescapeHtml(escapedHtml) {
  const htmlEntities = {
    "&amp;": "&",
    "&lt;": "<",
    "&gt;": ">",
    "&quot;": '"',
    "&#39;": "'",
  };

  return escapedHtml
    ?.replace(/&amp;|&lt;|&gt;|&quot;|&#39;/g, (match) => htmlEntities[match])
    ?.replaceAll('"{{media url="', `${process.env.NEXT_PUBLIC_IMAGE_BASEURL}/`)
    ?.replaceAll(`/media/" `, "/media/")
    ?.replaceAll('"}}"', "")
    ?.replaceAll(
      "{{media url=",
      `${process.env.NEXT_PUBLIC_IMAGE_BASEURL}/media/`
    )
    ?.replaceAll("}}", "");
}

export const validateImgSrc = (src) => {
  const newBaseUrl = process.env.NEXT_PUBLIC_IMAGE_BASEURL;

  let baseUrl = "";

  if (src?.startsWith("https://m.dev-almanea.com")) {
    baseUrl = "https://m.dev-almanea.com";
  } else if (src?.startsWith("https://almanea-stg.b-cdn.net")) {
    baseUrl = "https://almanea-stg.b-cdn.net";
  } else if (src?.startsWith("https://store.dev-almanea.com")) {
    baseUrl = "https://store.dev-almanea.com";
  }

  const finalSrc = !!baseUrl ? src?.replace(baseUrl, newBaseUrl) : src;
  return finalSrc?.replace("/media//", "/media/");
};

export const highlightText = (input, text) => {
  const escapeRegex = (str) => str?.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
  const words = input?.split(" ")?.filter(Boolean);
  const pattern = new RegExp(`(${words?.map(escapeRegex)?.join("|")})`, "giu");

  return text?.replace(pattern, (match) => `<strong>${match}</strong>`);
};

export const handleAddToActiveCart = async (handshake, order_id, push) => {
  // dispatchCart({ type: 'clear' });
  // dispatchCheckout({ type: 'clear' });
  const res = await activeCart(handshake, order_id);
  // const cartDetails = res?.data?.data?.details
  // const cartDetailsStatus = res?.data?.data?.success
  const cart_id = res?.data?.data?.cart_id;

  if (cart_id) {
    setCookie("quote", cart_id);
    setTimeout(() => {
      push("/cart");
    }, 200);
  }
};

export const checkSearchLang = (word, locale) => {
  const hasEnglish = /[a-zA-Z]/.test(word);
  const hasArabic = /[\u0600-\u06FF]/.test(word);

  if (hasEnglish && !hasArabic) {
    return "en";
  } else if (hasArabic) {
    return "ar";
  }
  return locale;
};

export function clearFalsyKeysFromObject(obj) {
  const filteredObj = {};

  for (const key in obj) {
    const value = obj[key];

    // Skip null or undefined values.
    if (value === null || value === undefined) continue;

    // If it's a string, skip it if it's empty after trim.
    if (typeof value === "string" && value.trim() === "") continue;

    // Otherwise, include the key.
    filteredObj[key] = value;
  }

  return filteredObj;
}
