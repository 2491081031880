import { highlightText } from "@/utils/global";
import React from "react";

export default function SuggestedBrands({
  title,
  data,
  redirectToSearch,
  searchTerm,
}) {
  return (
    <div className="mb-3">
      <h3 className="mb-2 text-sm font-semibold text-red">{title || ""}</h3>{" "}
      <div className="grid grid-cols-1 max-[570px]:grid-cols-3">
        {data?.map((brand, idx) => (
          <button
            onClick={() => {
              redirectToSearch(brand);
            }}
            // onMouseEnter={() => applySearch(brand)}
            key={`suggest_brand_term_${brand}_${(idx + 1) * 425}`}
            className="flex gap-2 p-2 text-xs transition hover:bg-slate-200 text-start align-items-center"
            dangerouslySetInnerHTML={{
              __html: highlightText(searchTerm, brand) || "",
            }}
          />
        ))}
      </div>
    </div>
  );
}
