import Link from "next/link";
import UserAuth from "./UserAuth";
// import Search from "./Search";
import Cookies from "js-cookie";
import SelectCity from "./SelectCity";
import {
  AiOutlineHeart,
  AiOutlineShoppingCart,
  AiOutlineUser,
  AiOutlineMenu,
} from "react-icons/ai";
import { MdOutlinePhoneInTalk } from "react-icons/md";
import { signOut, useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { ModalContext } from "@/pages/_app";
import { useContext, useEffect, useState } from "react";
import { useHandshake } from "@/hooks/useHandshake";
import { useCategoryTree } from "@/hooks/useCategoryTree";
import { AppContext } from "@/contexts/appContexts";
import { useCart } from "@/contexts/cart/CartContext";
import { useCheckIfAuth } from "@/hooks/useCheckIfAuth";
import { fastDeliveryAndAvailableDatesLogic } from "@/utils/global";
import dynamic from "next/dynamic";
import { event_page_viewed } from "@/utils/gtm";
import ImgTag from "../Helpers/ImgTag";
import TopHeader from "./TopHeader";

import AlgoliaSearch from "./AlgoliaSearch";

const CartPopUp = dynamic(() => import("./CartPopup"), { ssr: false });

function Header({
  t,
  offCanvas,
  setOffCanvas,
  offCanvasFromDesktop,
  setOffCanvasFromDesktop,
  // searchValue,
  // onSearch,
  showSearchBoxModal,
  setShowSearchBoxModal,
  citiesToSlect,
  setcitiesToSlect,
  invalidate: invalidateRegions,
  isLoadingRegions,
  homeJson,
  setAvailableDates,
  searchInputRef,
  openSearchTermsRef,
}) {
  const { data: session } = useSession();
  const { asPath, pathname, query, push, events, locale: lang } = useRouter();
  const { setCategoryData, setTargetProductDate, setIsCityHavefastDelivery } =
    useContext(AppContext);
  const Context = useContext(ModalContext);
  const { cart } = useCart();

  const [fetchCheckIfAuth, setFetchCheckIfAuth] = useState(false);
  const [fetchHandshake, setFetchHandshake] = useState(false);

  const { invalidate } = useHandshake(
    lang,
    Cookies,
    setCategoryData,
    fetchHandshake
  );
  const { invalidate: invalidateCategoryTree } = useCategoryTree(
    lang,
    setCategoryData
  );
  useCheckIfAuth(
    session?.mage,
    signOut,
    push,
    asPath,
    lang,
    Cookies,
    fetchCheckIfAuth
  );

  useEffect(() => {
    const is_login = session ? "yes" : "no";
    const user = session?.user;
    const phoneNumber = user?.custom_attributes?.find(
      (att) => att.attribute_code == "mobilenumber"
    )?.value;
    event_page_viewed(
      "SAR",
      lang,
      is_login,
      user?.id || null,
      user?.email || null,
      phoneNumber || null
    );
  }, [pathname]);

  useEffect(() => {
    setFetchCheckIfAuth(true);
    setFetchHandshake(true);
  }, []);

  useEffect(() => {
    if (session?.mage) Cookies.set("mage", session?.mage, { expires: 9 });
  }, [session?.mage]);

  const handleSetNetHandshake = () => {
    events.on("routeChangeComplete", () => {
      invalidate();
      invalidateRegions();
      invalidateCategoryTree();
      fastDeliveryAndAvailableDatesLogic({
        setAvailableDates,
        setTargetProductDate,
        homeJson,
        setIsCityHavefastDelivery,
        id: Cookies.get("region_id"),
        locale: lang === "en" ? "ar" : "en",
      });
    });
  };

  const { wishList } = useContext(AppContext);
  const dynamicStyle =
    process.env.NEXT_PUBLIC_WEBSITE_MODE == "extreme"
      ? "text-red bg-white"
      : "text-white bg-red";
  const dynamicHoveStyle =
    process.env.NEXT_PUBLIC_WEBSITE_MODE == "extreme"
      ? "hover:text-white hover:bg-red border-white"
      : "hover:text-red hover:bg-[white] border-red";

  return (
    <section
      className={`${
        process.env.NEXT_PUBLIC_WEBSITE_MODE == "extreme"
          ? "bg-[url('https://imgs.dev-almanea.com/media/almanea-pattern-bg-min.webp')] bg-fixed bg-center"
          : "bg-white"
      }`}
    >
      <TopHeader />
      <header className="w-[93%] max-[570px]:w-[98%] mx-auto pt-5 pb-3 max-[991px]:py-2">
        <div className="flex items-center justify-between gap-x-2">
          <Link
            href="/"
            className="relative overflow-hidden bg-white rounded-md w-36"
          >
            <ImgTag
              src={`${process.env.NEXT_PUBLIC_CLOUD_FLAIR}/w=160&h=90,fit=contain,format=auto/almanea-logo.webp`}
              className="object-contain pe-8 ps-2 w-[160px] h-12"
              width={160}
              height={90}
              sizes="(min-width: 340px) 160px, calc(70vw - 93px)"
              alt="Logo"
            />
          </Link>
          <div
            className="flex items-center gap-2 mx-2 max-[570px]:hidden"
            onClick={() => {
              setOffCanvas(!offCanvas);
              setOffCanvasFromDesktop(!offCanvasFromDesktop);
            }}
          >
            <button
              aria-label="Burger Menu"
              className={`block p-2 mx-1 ${dynamicStyle} ${dynamicHoveStyle} border-[1px] rounded-full  max-[991px]:hidden `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                version="1"
                viewBox="0 0 512 512"
                fill="none"
              >
                <path
                  fill="currentColor"
                  d="M725 4672c-120-42-205-117-258-230l-32-67V744l37-75c46-93 112-158 206-202l67-32h1280l58 23c72 29 172 120 210 191 59 107 57 32 57 1911s2 1804-57 1911c-38 71-138 162-210 191l-58 23-625 2c-573 2-629 1-675-15zm1195-2112V850l-532 2-533 3-3 1695c-1 932 0 1701 3 1708 3 9 120 12 535 12h530V2560zM3070 4671c-30-9-73-29-95-43-53-33-128-115-155-168-46-91-50-139-50-720 0-303 4-571 10-604 30-183 173-326 356-356 33-6 301-10 604-10 581 0 629 4 721 50 54 28 142 111 173 164 57 97 57 98 54 772l-3 619-32 67c-44 94-109 160-202 206l-75 37-625 2c-577 2-630 1-681-16zm1198-938l2-533H3200v1070l533-2 532-3 3-532zM3127 2340c-173-30-318-178-347-356-6-33-10-301-10-604 0-581 4-629 50-721 28-54 111-142 164-173 97-57 98-57 773-54l619 3 75 37c93 46 158 112 202 206l32 67 3 619c3 674 3 675-54 772-31 53-119 136-173 164-92 47-138 50-731 49-305-1-577-5-603-9zm1141-952l-3-533-532-3-533-2v1070h1070l-2-532z"
                  transform="matrix(.1 0 0 -.1 0 512)"
                ></path>
              </svg>
            </button>
            <span
              className={`cursor-pointer ${
                process.env.NEXT_PUBLIC_WEBSITE_MODE == "extreme" &&
                "text-white"
              }`}
            >
              {t("Categories")}
            </span>
          </div>
          <SelectCity
            {...{
              citiesToSlect,
              setcitiesToSlect,
              t,
              lang,
              responsive: false,
              setAvailableDates,
              setTargetProductDate,
              isLoadingRegions,
              homeJson,
            }}
          />
          <div className="relative justify-center flex-1 mx-1">
            <div
              className={`max-[991px]:hidden relative ${
                process.env.NEXT_PUBLIC_WEBSITE_MODE != "extreme" &&
                "border border-red"
              }  mx-4 rounded-md`}
            >
              <AlgoliaSearch
                {...{
                  showSearchBoxModal,
                  setShowSearchBoxModal,
                  searchInputRef,
                  openSearchTermsRef,
                }}
              />
            </div>
          </div>
          <div
            className={`${
              session ? "min-w-[300px]" : "min-w-fit"
            } max-[991px]:min-w-fit flex items-center justify-end`}
          >
            {session ? (
              <UserAuth {...{ session, Cookies }} />
            ) : (
              <span
                onClick={() => Context?.dispatch({ type: "authModal" })}
                className={`p-2 mx-2 rounded-full cursor-pointer max-[991px]:mx-1 ${dynamicStyle} ${dynamicHoveStyle} border-[1px] max-[991px]:hidden`}
              >
                <AiOutlineUser size="24px" />
              </span>
            )}
            <a
              href="https://customer.almanea.sa/login/"
              target="_blank"
              className={`p-2 mx-2 ${dynamicStyle} ${dynamicHoveStyle} border-[1px] rounded-full cursor-pointer max-[991px]:mx-1 max-[991px]:hidden`}
            >
              <MdOutlinePhoneInTalk size="24px" />
            </a>
            <div
              className={`mx-2 cursor-pointer ${dynamicStyle} ${dynamicHoveStyle} duration-200 border-[1px] p-2 rounded-full relative max-[991px]:hidden group`}
            >
              <AiOutlineShoppingCart size="1.5rem" />
              <span
                suppressHydrationWarning
                className="absolute -top-2 ltr:-right-1 rtl:-left-1 px-1.5 text-sm rounded-full bg-red text-white font-semibold group"
              >
                {cart?.cartItems?.length || ""}
              </span>
              <CartPopUp
                suppressHydrationWarning={true}
                {...{ items: cart?.cartItems, lang, t }}
              />
            </div>

            <div
              onClick={() => push("/user/favourites")}
              className={`mx-2 ${dynamicStyle} ${dynamicHoveStyle} duration-200  border-[1px] p-2 rounded-full relative max-[991px]:hidden cursor-pointer`}
            >
              <Link href="/user/favourites">
                <AiOutlineHeart size="24px" />
              </Link>
              <span className="absolute -top-2 ltr:-right-1 rtl:-left-1 px-1.5 text-sm rounded-full bg-red text-white font-semibold">
                {wishList && wishList.length > 0 ? wishList.length : ""}
              </span>
            </div>

            <Link
              onClick={() => handleSetNetHandshake()}
              href={{ pathname, query }}
              as={asPath}
              locale={lang === "en" ? "ar" : "en"}
              className={`mx-2 w-10 h-10 ${dynamicStyle} ${dynamicHoveStyle} duration-200 border-[1px] text-center rounded-full relative max-[991px]:hidden flex items-center justify-center ltr:text-xl ltr:pb-2 rtl:txt-lg`}
            >
              {lang === "en" ? "ع" : "EN"}
            </Link>

            <div
              onClick={() => push("/cart")}
              className={`mx-2 max-sm:mx-1 cursor-pointer ${dynamicStyle} ${dynamicHoveStyle} duration-200 border-[1px] p-2 rounded-full relative max-[768px]:block max-[991px]:block hidden group`}
            >
              <AiOutlineShoppingCart className="w-6 h-6 max-md:w-3.5 max-md:h-3.5" />
              <span
                suppressHydrationWarning
                className="absolute -top-2 ltr:-right-1 rtl:-left-1 px-1.5 text-sm rounded-full ${dynamicStyle} font-semibold group"
              >
                {cart?.cartItems?.length || ""}
              </span>
            </div>
            <button
              aria-label="Burger Menu"
              className={`hidden p-2 ms-1 ${dynamicStyle} rounded-full max-[991px]:block`}
            >
              <AiOutlineMenu
                className="w-6 h-6 max-md:w-3.5 max-md:h-3.5"
                onClick={() => setOffCanvas(true)}
              />
            </button>
          </div>
        </div>

        <div
          className={`hidden max-[991px]:block relative border ${
            process.env.NEXT_PUBLIC_WEBSITE_MODE != "extreme" && "border-red"
          } rounded-md mt-1`}
        >
          <AlgoliaSearch
            {...{
              showSearchBoxModal,
              setShowSearchBoxModal,
              searchInputRef,
              openSearchTermsRef,
            }}
          />
        </div>
      </header>
    </section>
  );
}

export default Header;
