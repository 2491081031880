import { useContext, useMemo } from "react";
import CommonCategory from "./CommonCategory";
import SavedWords from "./SavedWords";
import { AppContext } from "@/contexts/appContexts";
import { getObjectsInDataByKey } from "@/utils/global";
import TopSearchTerms from "./TopSearchTerms";
import { topSearchTermsReq } from "@/services/search";
import Cookies from "js-cookie";
import useSWR from "swr";
import { useRouter } from "next/router";

const InitialSearchSuggestion = ({ clearSearch, redirectToSearch }) => {
  const { savedSearchWords, categoryData } = useContext(AppContext);
  const handshake = Cookies.get("handshake");
  const { locale } = useRouter();

  const CategoriesData = useMemo(
    () =>
      getObjectsInDataByKey(
        categoryData?.children_data,
        "show_in_search",
        true
      ),
    [categoryData?.children_data]
  );

  const { data: topSearchTermsData } = useSWR(
    `${handshake}-${locale}`,
    async () => topSearchTermsReq(handshake),
    {
      revalidateOnFocus: false,
    }
  );

  return (
    <div className="flex items-center justify-center ">
      <div className="flex flex-col max-[991px]:flex-row mx-5 max-[991px]:gap-6 w-full">
        {savedSearchWords?.length ? (
          <SavedWords
            applySearch={redirectToSearch}
            savedSearchWords={savedSearchWords}
            title={
              locale == "en" ? "Recently Searched" : "عمليات البحث الأخيرة"
            }
          />
        ) : null}

        {CategoriesData?.length ? (
          <CommonCategory
            clearSearch={clearSearch}
            CategoriesData={CategoriesData}
            title={locale == "en" ? "Popular Brands" : "ماركات مشهورة"}
          />
        ) : null}

        {topSearchTermsData?.data?.data?.response?.length ? (
          <TopSearchTerms
            applySearch={redirectToSearch}
            topSearchTermsData={topSearchTermsData?.data?.data?.response}
            title={locale == "en" ? "Trending" : "متداول"}
          />
        ) : null}
      </div>
    </div>
  );
};

export default InitialSearchSuggestion;
