import React, { useCallback } from "react";
import SearchTermsBox from "./SearchTermsBox";
import { AiOutlineSearch } from "react-icons/ai";
import { ImCross } from "react-icons/im";

const SearchBox = ({
  locale,
  handleInputFocus,
  openSearchPopupTerms,
  openSearchTermsRef,
  redirectToSearch,
  setShowSearchBoxModal,
  setOpenSearchPopupTerms,
  setInputValueHandler,
  inputValue,
  products,
  suggestions,
  handleSearch,
}) => {
  const handleInputChange = (event) => {
    const searchValue = event.currentTarget.value;
    setInputValueHandler(searchValue);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && inputValue.trim().length >= 3) {
      redirectToSearch(inputValue);
    }
  };

  const handleClearSearch = useCallback(() => {
    setInputValueHandler("");
    setShowSearchBoxModal(false);
    setOpenSearchPopupTerms(false);
  }, [setInputValueHandler, setOpenSearchPopupTerms, setShowSearchBoxModal]);

  return (
    <>
      <div className="relative">
        <input
          id="search-box"
          placeholder={
            locale === "ar"
              ? "ابحث فى المتجر كامل من هنا"
              : "Search For Any Product Here..."
          }
          className="w-full px-3 py-2 rounded-md outline-none max-sm:placeholder:text-xs max-sm:text-xs"
          autoComplete="off"
          value={inputValue}
          onClick={handleInputFocus}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />
        <div
          className={`${
            inputValue ? "cursor-pointer" : "pointer-events-none"
          } absolute inset-y-0 ${
            locale === "en" ? "right-0" : "left-0"
          } flex items-center pl-3`}
        >
          {!inputValue ? (
            <AiOutlineSearch className="absolute -translate-y-1/2 max-sm:w-4 max-sm:h-4 ltr:right-3 rtl:left-3 top-1/2 text-zinc-500" />
          ) : (
            <ImCross
              onClick={handleClearSearch}
              className="h-4 w-4 text-gray-400 absolute cursor-pointer ltr:right-3 rtl:left-3"
              aria-label="Clear search"
            />
          )}
        </div>
      </div>

      {openSearchPopupTerms && (
        <SearchTermsBox
          inputValue={inputValue}
          openSearchTermsRef={openSearchTermsRef}
          products={products}
          suggestions={suggestions}
          redirectToSearch={redirectToSearch}
          clearSearch={handleClearSearch}
          setInputValue={setInputValueHandler}
          handleSearch={handleSearch}
        />
      )}
    </>
  );
};

export default SearchBox;
