import SuggestWords from "./SuggestWords";
// import useSWR from "swr";
// import { handleSearch } from "@/services/header/search";
// import { getCookie } from "cookies-next";
import SuggestProducts from "./SuggestProducts";
// import { typeSenseMultiSearch } from "@/services/typesense/multiSearch";
// import { useRouter } from "next/router";
// import { IS_TYPESENSE_SEARCH } from "@/utils/typesense/config";

// import { useTranslation } from "next-i18next";

const FinalSearchSuggestion = ({
  clearSearch,
  products,
  inputValue,
  redirectToSearch,
  suggestions,
  handleSearch,
}) => {
  // const {
  //   i18n: { language },
  // } = useTranslation("common");
  // const { locale } = useRouter();
  // const region_id = getCookie("region_id");
  // const handshake = getCookie("handshake");
  // const PAGE_SIZE = 9;
  // const [suggestedText, setSuggestedText] = useState("");
  // const [products, setProducts] = useState([]);
  // const [suggestions, setSuggestions] = useState(null);
  // const previousSearchValue = useRef(null);

  const handleMouseEnter = (word) => {
    handleSearch(word, true);
  };

  // const {} = useSWR(
  //   () =>
  //     IS_TYPESENSE_SEARCH
  //       ? `typesense-getSearchData/${suggestedText}/${region_id}`
  //       : `getSearchData/${suggestedText}/${region_id}`,
  //   () =>
  //     IS_TYPESENSE_SEARCH
  //       ? typeSenseMultiSearch(
  //           suggestedText,
  //           1,
  //           PAGE_SIZE,
  //           [],
  //           locale,
  //           region_id
  //         )
  //       : handleSearch({
  //           token: handshake,
  //           region_id,
  //           word: suggestedText,
  //           pageNo: 0,
  //         }),
  //   {
  //     revalidateOnFocus: false,
  //     revalidateOnMount: !!suggestedText,
  //     onSuccess: (res) => {
  //       setProducts(res?.data?.products || []);
  //       if (
  //         previousSearchValue.current !== inputValue &&
  //         res?.data?.products?.length > 0
  //       ) {
  //         previousSearchValue.current = inputValue;

  //         const words =
  //           res?.data?.suggestedWords?.length > 0
  //             ? res?.data?.suggestedWords
  //             : [
  //                 ...new Set(
  //                   (res?.data?.products || [])
  //                     ?.slice(0, 6)
  //                     ?.map((item) => item?._source?.suggest)
  //                     .flat()
  //                     ?.filter(
  //                       (term) =>
  //                         term?.length >= 3 &&
  //                         !term?.includes(",") &&
  //                         !term?.includes("،") &&
  //                         !term?.includes(".")
  //                     )
  //                     ?.slice(0, 20)
  //                     ?.map((word) => word?.toLowerCase())
  //                 ),
  //               ];

  //         const brands = [
  //           ...new Set(
  //             (res?.data?.products || [])
  //               ?.slice(0, 6)
  //               ?.map((prod) =>
  //                 Array.isArray(prod?._source?.option_text_brand)
  //                   ? prod?._source?.option_text_brand?.at(0)?.toLowerCase()
  //                   : prod?._source?.option_text_brand?.toLowerCase()
  //               )
  //           ),
  //         ];

  //         const suggestedWords = words.filter((word) => {
  //           return !brands.includes(word);
  //         });

  //         setSuggestions({
  //           words: suggestedWords,
  //           brands: brands?.filter((i) => i),
  //           categories: res?.data?.categorys || [],
  //         });
  //       }
  //     },
  //   }
  // );

  // useEffect(() => {
  //   // Clear any existing timers and set a new one
  //   const debounce = setTimeout(() => {
  //     if (inputValue) {
  //       setSuggestedText(inputValue); // This will trigger the `useEffect`
  //     }
  //   }, 1000);

  //   // Clean up previous debounce when SuggestedText changes
  //   return () => {
  //     clearTimeout(debounce);
  //   };
  // }, [inputValue]); // Effect will only trigger when SuggestedText changes

  return (
    // <>
    //   {!!suggestions && (
    //     <div className="grid grid-cols-5 max-[991px]:grid-cols-1 gap-2 p-3 ">
    //       <div className="col-span-1">
    //         <SuggestWords
    //           applySearch={applySearch}
    //           suggestions={suggestions}
    //           setSuggestedText={setSuggestedText}
    //           inputValue={inputValue}
    //         />
    //       </div>
    //       <div className="col-span-4">
    //         <SuggestProducts products={products} clearSearch={clearSearch} />
    //       </div>
    //     </div>
    //   )}
    // </>
    <>
      {(!!suggestions?.length || !!products?.length) && (
        <div className="grid grid-cols-5 max-[991px]:grid-cols-1 gap-2 p-3 ">
          <div className="col-span-1">
            <SuggestWords
              suggestions={suggestions}
              products={products}
              inputValue={inputValue}
              handleMouseEnter={handleMouseEnter}
              redirectToSearch={redirectToSearch}
              clearSearch={clearSearch}
            />
          </div>
          <div className="col-span-4">
            <SuggestProducts products={products} clearSearch={clearSearch} />
          </div>
        </div>
      )}
    </>
  );
};

export default FinalSearchSuggestion;
