import Header from "@/components/Layout/Header";
import Nav from "@/components/Layout/Nav";
const Offcanvas = dynamic(() => import("../Layout/Offcanvas"), { ssr: false });
import FixedBox from "../Layout/FixedBox";
// import Search from "@/components/Search/index";
import Cookies from "js-cookie";
import browserStorage from "store";
import useRegions from "@/hooks/useRegions";
import SelectCityPopUp from "../Helpers/SelectCityPopUp";
import useHomeJson from "@/hooks/useHomeJson";
import getDeliveryDate from "@/services/header/getDeliveryDate";
import DateCode from "@/constants/DateCode";
import { SWRConfig } from "swr";
import { CREATE_CART, GET_CART, MERGE_MASKED_CART } from "@/services/cart";
import {
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
} from "react";
import { useCart } from "@/contexts/cart/CartContext";
import { AppContext } from "@/contexts/appContexts";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { decodeJwt, formatDate, handleAddToActiveCart } from "@/utils/global";
import { useTranslation } from "next-i18next";
import dynamic from "next/dynamic";
import { event_payment_failed, event_user_logout } from "@/utils/gtm";
import Head from "next/head";
import { ModalContext } from "@/pages/_app";
import { deleteCookie, getCookie } from "cookies-next";
import usePreventScrollOnBack from "@/hooks/usePreventScrollOnBack";
const Footer = dynamic(() => import("../Layout/footer/Footer"), { ssr: false });
import AlgoliaSearch from "./AlgoliaSearch";
function Layout({ children, className }) {
  usePreventScrollOnBack();
  const isLoggedOut = getCookie("out");
  const Context = useContext(ModalContext);
  const { t } = useTranslation("common");
  const openSearchTermsRef = useRef(null);
  const [offCanvas, setOffCanvas] = useState(false);
  const [offCanvasFromDesktop, setOffCanvasFromDesktop] = useState(false);
  const { dispatch } = useCart();
  // const clickRef = useRef();
  const searchInputRef = useRef();
  const quote = Cookies.get("quote");
  const { data: session } = useSession();
  const {
    pathname,
    locale,
    query,
    asPath,
    events,
    push,
    // beforePopState
  } = useRouter();

  const mage = getCookie("mage");

  useEffect(() => {
    function isCookieNumber(cookie) {
      const pattern = /^\d+$/;
      return pattern.test(cookie);
    }

    const quote = getCookie("quote");
    if (
      mage &&
      locale == decodeJwt(mage)?.store &&
      decodeJwt(mage)?.exp > Date.now()
    ) {
      deleteCookie("mage");
      isCookieNumber(quote) && deleteCookie("quote");
    }
  }, [locale, mage]);

  const { renderFlipper, setRenderFlipper } = useState(false);
  if (query.success == true && renderFlipper == false) {
    setRenderFlipper(true);
  }

  // main state of search
  const [searchValue, setSearchValue] = useState("");

  // setter of search value, use it instead of passing setSearchValue (onSearch)
  const searchValueHandler = (value) => {
    setSearchValue(value);
  };

  const lang = locale;
  const [showSearchBoxModal, setShowSearchBoxModal] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [availableDates, setAvailableDates] = useState({});

  // Direction
  useLayoutEffect(() => {
    document.body.style.direction = locale === "en" ? "ltr" : "rtl";
  }, [locale]);

  const {
    setRegionId,
    openSelectCityPopUp,
    setOpenSelectCityPopUp,
    setTargetProductDate,
    scrollPositionPages,
    setScrollPositionPages,
    applyScrollPositionPages,
    setApplyScrollPositionPages,
  } = useContext(AppContext);

  const { regions, invalidate, isLoading: isLoadingRegions } = useRegions(lang);
  useEffect(() => {
    if (isLoggedOut === "true") {
      console.log("datalayer 2", isLoggedOut);
      const usr = browserStorage.get("usr")
        ? JSON.parse(browserStorage.get("usr"))
        : { firstname: null, lastname: null, email: null, id: null };
      setTimeout(() => {
        event_user_logout(usr?.firstname, usr?.lastname, usr?.email, usr?.id);
        deleteCookie("out");
        browserStorage.remove("usr");
      }, 3000);
    }
    if (query.success == "false" && typeof window != undefined) {
      setTimeout(() => {
        event_payment_failed(
          parseInt(Cookies.get("amount")),
          Cookies.get("payment"),
          query.reason
        );
      }, 4000);
    }
    if (query?.event === "register") {
      setTimeout(() => {
        Context?.dispatch({ type: "authModal" });
      }, 2000);
    }
    if (query?.event === "forgetpassword") {
      setTimeout(() => {
        Context?.dispatch({ type: "forgotModal" });
      }, 2000);
    }
    if (query?.event === "newsletter") {
      setTimeout(() => {
        if (typeof window != undefined) {
          const input = document.getElementById("newsletter");
          const acc = document.getElementById("newsletter-accordion");
          if (acc) {
            acc.click();
            acc?.scrollIntoView({ behavior: "smooth" });
            const input = document.getElementById("newsletter");
            input && input?.focus();
          }
          if (input) {
            input?.scrollIntoView({ behavior: "smooth" });
            input?.focus();
          }
        }
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const [citiesToSlect, setcitiesToSlect] = useState(regions);
  const region_id = Cookies.get("region_id");
  const urlWithoutParams = asPath.includes("?")
    ? asPath?.slice(0, asPath.indexOf("?"))
    : asPath;

  useEffect(() => {
    let dir = locale == "ar" ? "rtl" : "ltr";
    let lang = locale == "ar" ? "ar" : "en";
    document.querySelector("html").setAttribute("dir", dir);
    document.querySelector("html").setAttribute("lang", lang);
  }, [locale]);

  useEffect(() => {
    const handleGetDeliveryDate = async () => {
      const res =
        region_id && !isNaN(region_id)
          ? await getDeliveryDate(DateCode[region_id])
          : {};
      const AvailableDates = res?.data?.data?.data?.AvailableDates;
      const availableDatesChecked =
        region_id &&
        !isNaN(region_id) &&
        AvailableDates &&
        AvailableDates != undefined &&
        Array.isArray(AvailableDates) &&
        AvailableDates?.length;

      setTargetProductDate &&
        availableDatesChecked &&
        setTargetProductDate({
          Date:
            formatDate(
              AvailableDates?.[0]?.Date || "",
              AvailableDates?.[0]?.Day || "",
              locale
            ) || "",
          Day: AvailableDates?.[0]?.Day || "",
        });

      availableDatesChecked &&
        setAvailableDates({
          [DateCode[region_id]]: AvailableDates?.[0],
        });
    };
    (pathname == "/" || pathname.includes("category")) &&
      handleGetDeliveryDate();
  }, [pathname, setTargetProductDate, region_id, locale]);

  const [fetchHomeJson, setFetchHomeJson] = useState(false);
  const homeJson = useHomeJson(lang, fetchHomeJson);

  useEffect(() => {
    setFetchHomeJson(true);
  }, []);

  useEffect(() => {
    setcitiesToSlect(regions?.slice(1));
  }, [regions]);

  useEffect(() => {
    if (quote?.match(/^\d+$/) === null && session) {
      const { store_id, id } = session?.user || {};
      MERGE_MASKED_CART(store_id, id).then((res) => {
        if (res.error === null && res.data?.data) {
          CREATE_CART().then(({ error, data }) => {
            if (!error) {
              Cookies.set("quote", data?.data?.quote);
            }
            GET_CART().then((res) => {
              browserStorage.set("cart", {
                cart: res?.data?.data,
                cartItems: res?.data?.items,
              });
              dispatch({ type: "GET_CART", payload: res?.data?.data });
            });
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  useEffect(() => {
    Array.isArray(homeJson?.data?.data) &&
      homeJson?.data?.data?.length &&
      homeJson?.data?.data[0]["popup-banner"] &&
      browserStorage.set(
        "popup-banner",
        JSON.stringify(homeJson?.data?.data[0]["popup-banner"][0])
      );
  }, [homeJson?.data?.data]);

  // Function to scroll with smooth behavior if supported, else fallback
  const smoothScroll = (top) => {
    if ("scrollBehavior" in document.documentElement.style) {
      window.scrollTo({ top, behavior: "smooth" });
    } else {
      window.scrollTo(0, top); // Fallback for older browsers or Safari
    }
  };

  useEffect(() => {
    // Function to scroll to the specified position
    const scrollToTargetPosition = () => {
      setTimeout(() => {
        if (typeof window != undefined) {
          if (
            applyScrollPositionPages &&
            scrollPositionPages &&
            asPath in scrollPositionPages
          ) {
            if (!pathname?.includes("/[category")) {
              // window.scrollTo({
              //   top: scrollPositionPages[asPath],
              //   behavior: "smooth", // Optional: Adds a smooth scroll animation
              // });
              smoothScroll(scrollPositionPages[asPath]); // Scroll to saved position
              setApplyScrollPositionPages(false);
            }
          } else {
            smoothScroll(0); // Scroll to top if no position is saved
          }
        }
      }, 500);
    };

    // Scroll to the desired position when the component mounts
    events.on("routeChangeComplete", scrollToTargetPosition);

    // Clean up event listener on unmount
    return () => {
      events.off("routeChangeComplete", scrollToTargetPosition);
    };
  }, [
    asPath,
    events,
    pathname,
    scrollPositionPages,
    setApplyScrollPositionPages,
    applyScrollPositionPages,
  ]);

  useEffect(() => {
    const handleSetScroll = () => {
      setTimeout(() => {
        if (typeof window !== undefined && !pathname?.includes("/[category")) {
          setScrollPositionPages((prev) => ({
            ...prev,
            [asPath]: window.scrollY,
          }));
        }
      }, 500); // Adjust delay as needed
    };
    // Attach event listener for scroll
    window.addEventListener("scroll", handleSetScroll);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("scroll", handleSetScroll);
    };
  }, [asPath, pathname, setScrollPositionPages]);

  const [topHeaderHeight, setTopHeaderHeight] = useState(0);
  const topHeaderRef = useRef(0);
  useEffect(() => {
    const updateHeight = () => {
      if (topHeaderRef.current) {
        console.log("topHeaderRef", topHeaderRef.current.offsetHeight);
        setTopHeaderHeight(topHeaderRef.current.offsetHeight);
      }
    };

    // Initialize ResizeObserver
    const resizeObserver = new ResizeObserver(updateHeight);
    if (topHeaderRef.current) {
      resizeObserver.observe(topHeaderRef.current);
    }

    // Calculate initial height
    updateHeight();

    // Cleanup observer on unmount
    return () => {
      if (topHeaderRef.current) {
        resizeObserver.unobserve(topHeaderRef.current);
      }
    };
  }, [topHeaderRef]);

  useEffect(() => {
    if (
      (query?.success == "false" || query?.success == false) &&
      query?.order_id
    ) {
      handleAddToActiveCart(Cookies.get("handshake"), query?.order_id, push);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <SWRConfig value={{ provider: () => new Map() }}>
      <Head>
        {!pathname.startsWith("/product/") &&
          !pathname === "/[...categorySlug]" && (
            <link
              rel="canonical"
              href={`${process.env.NEXT_PUBLIC_WEBSITE_URL}${
                locale === "ar" ? "" : "/en"
              }${urlWithoutParams === "/" ? "" : urlWithoutParams}`}
            />
          )}
      </Head>
      <main
        className={`${className} ${
          process.env.NEXT_PUBLIC_WEBSITE_MODE == "extreme" &&
          "bg-[#8A0000] bg-[url('https://imgs.dev-almanea.com/media/almanea-pattern-bg-min.webp')] bg-fixed bg-center "
        } min-h-screen`}
      >
        <div
          ref={topHeaderRef}
          className="upper-part sticky top-0 right-0 left-0 z-[3000] w-full"
        >
          <Header
            {...{
              offCanvas,
              setOffCanvas,
              offCanvasFromDesktop,
              setOffCanvasFromDesktop,
              t,
              searchValue,
              setSearchValue,
              onSearch: searchValueHandler,
              showSearchBoxModal,
              setShowSearchBoxModal,
              citiesToSlect,
              setcitiesToSlect,
              invalidate,
              isLoadingRegions,
              homeJson,
              setAvailableDates,
              searchInputRef,
              openSearchTermsRef,
            }}
          />
          {offCanvas ? (
            <Offcanvas
              {...{
                offCanvas,
                setOffCanvas,
                t,
                offCanvasFromDesktop,
                setOffCanvasFromDesktop,
                topHeaderHeight,
              }}
            />
          ) : null}
          <Nav
            {...{
              t,
              onSearch: searchValueHandler,
              citiesToSlect,
              setcitiesToSlect,
              invalidate,
              setAvailableDates,
              isLoadingRegions,
              homeJson,
            }}
          />
        </div>

        {!showSearchBoxModal ? (
          <div
            className={`${
              pathname != "/" && "max-w-[1600px] mx-auto min-h-[60vh]"
            }`}
          >
            {children}
          </div>
        ) : (
          <div className="">
            <AlgoliaSearch
              {...{
                setShowSearchBoxModal,
                showSearchBoxModal,
                searchInputRef,
                openSearchTermsRef,
              }}
            />
          </div>
        )}
        <SelectCityPopUp
          {...{
            isModalOpen: openSelectCityPopUp,
            setIsModalOpen: setOpenSelectCityPopUp,
            setRegionId,
            setAvailableDates,
            regionsData: regions,
          }}
        />
        <Footer {...{ t }} />
        {false && <FixedBox {...{ t }} />}
      </main>
    </SWRConfig>
  );
}

export default Layout;

/* 
mage sell logic
  import Compaign from "../campaign";
  =========================================<| MEGA SELL |>=========================================
  =========================================<| MEGA SELL |>=========================================
  const [hideWebsite, setHideWebsite] = useState(true);
  // const targetDate = new Date(Date.UTC(2024, 4, 23, 8, 59, 59));
  const targetDate = new Date("2024/10/24 09:00:00");

  // Convert to KSA locale datetime
  const ksaDateTime = targetDate.toLocaleString("en-SA");

  console.log(ksaDateTime);

  const openAtDate = new Date("2024/10/24 08:20:00");
  useEffect(() => {
    // Function to check the current date and time
    const checkDate = () => {
      const now = new Date();
      if (now?.getTime() >= openAtDate?.getTime()) {
        setHideWebsite(false);
      } else {
        setHideWebsite(true);
      }
    };
    // Check date immediately on component mount
    checkDate();
    // Set up an interval to check the date every second
    const interval = setInterval(checkDate, 1000);
    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  });
  =========================================<| MEGA SELL |>=========================================
  =========================================<| MEGA SELL |>=========================================

  
  useEffect(() => {
    events.on("routeChangeStart", () => {
      beforePopState(({ as, options }) => {
        // Detect browsers go back action
        console.log('out condition as !== asPath' ,options)
        if (as !== asPath) {
          console.log('as !== asPath')
          // window.history.pushState(null, null, asPath);
          push(as)
          window.scrollTo({
            top: 0,
            behavior: "smooth", // Optional: Adds a smooth scroll animation
          });
          // Handle the back button press here
          // setTimeout(() => {
          // }, 0)
        }
      });
    })
  }, [asPath, beforePopState, events, push]);

  if (
    (hideWebsite === true && query?.show === "almanea") ||
    hideWebsite === false
  )
  if (query?.show === "almanea" || !hideWebsite) {
  
  }


if (hideWebsite) return <Compaign date={targetDate} />;
} else {
  // TODO remove this block to show full website
  return (
    <div className="flex items-center justify-center">
      <ImgTag
        src="https://images-r2.dev-almanea.com/almanea-desktop-min.jpg"
        className="block object-contain w-screen h-screen max-[570px]:hidden"
      />
      <ImgTag
        src="https://images-r2.dev-almanea.com/almanea-mobile-min.jpg"
        className="hidden object-contain w-screen h-screen max-[570px]:block"
      />
    </div>
  );
}
  */
