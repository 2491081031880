import { useState, useEffect, useContext } from "react";
import { useSlugs } from "@/contexts/categoryTree";
import { AppContext } from "@/contexts/appContexts";

const useFilteredCategories = (products) => {
  const [filteredCategories, setFilteredCategories] = useState([]);
  const { slugs } = useSlugs();
  const { categoryData } = useContext(AppContext);

  useEffect(() => {
    if (!slugs || !categoryData?.children_data || !products?.length) return;

    const categoriesId = new Set(
      products
        .flatMap((e) => e?.categoryIds?.map(Number) || [])
        .filter((id) => slugs[id])
    );

    if (!categoriesId.size) return;

    const findCategories = (categories) => {
      return categories?.reduce((acc, category) => {
        if (categoriesId.has(category?.id)) acc.push(category);
        if (category?.children_data?.length) {
          acc.push(...findCategories(category?.children_data));
        }
        return acc;
      }, []);
    };

    setFilteredCategories(findCategories(categoryData?.children_data));
  }, [categoryData, products, slugs]);

  return filteredCategories;
};

export default useFilteredCategories;
