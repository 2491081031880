import { searchClient } from "@/utils/searchClients";
import { InstantSearch } from "react-instantsearch-hooks";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import { useContext, useState, useCallback, useEffect } from "react";
import { AppContext } from "@/contexts/appContexts";
import useOnClickOutside from "@/hooks/useOnClickOutside";
import SearchBox from "./SearchBox";
import { checkSearchLang } from "@/utils/global";
import store from "store";

const DEBOUNCE_DELAY = 300;

function AlgoliaSearch({
  showSearchBoxModal,
  setShowSearchBoxModal,
  searchInputRef,
  openSearchTermsRef,
}) {
  const { locale, push, query, pathname } = useRouter();
  const regionId = Cookies.get("region_id");
  const { setOpenSelectCityPopUp, setProductInfo } = useContext(AppContext);
  const { savedSearchWords, setSavedSearchWords } = useContext(AppContext);

  const [inputValue, setInputValue] = useState("");

  const [products, setProducts] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  const [openSearchPopupTerms, setOpenSearchPopupTerms] = useState(false);
  useOnClickOutside([openSearchTermsRef], () => setOpenSearchPopupTerms(false));

  // this for updating the search input when redirect to search page and update the savedSearchWords
  useEffect(() => {
    if (pathname !== "/search") {
      setInputValue("");
    } else {
      let searchedQuery = query?.value;
      setInputValue(searchedQuery || "");
      setProducts([]);

      if (searchedQuery?.length >= 3) {
        setSavedSearchWords((prev) => {
          const updatedWords = new Set(prev);
          if (!updatedWords.has(searchedQuery)) {
            updatedWords.add(searchedQuery);
            store.set("savedSearchWords", Array.from(updatedWords));
            return Array.from(updatedWords);
          }
          return prev;
        });
      }
    }
  }, [query?.value, pathname]);

  // Function to get the correct index based on locale
  const getIndexName = (searchLang, type = "default") => {
    if (searchLang === "ar") {
      return type === "suggestions"
        ? process.env.NEXT_PUBLIC_ALGOLIA_IDX_SUGGESTIONS_AR
        : process.env.NEXT_PUBLIC_ALGOLIA_IDX_AR;
    }
    return type === "suggestions"
      ? process.env.NEXT_PUBLIC_ALGOLIA_IDX_SUGGESTIONS_EN
      : process.env.NEXT_PUBLIC_ALGOLIA_IDX_EN;
  };

  const redirectToSearch = (value) => {
    const trimmedValue = value.trim();
    push(`/search?value=${encodeURIComponent(trimmedValue)}`);
    setOpenSearchPopupTerms(false);
  };

  const handleInputFocus = () => {
    if (!regionId) {
      setOpenSelectCityPopUp(true);
      setProductInfo({ searchInputRef });
    } else if (!showSearchBoxModal) {
      setOpenSearchPopupTerms(true);
    }
  };

  const handleSearch = useCallback(
    (word, isFromHover = false) => {
      if (handleSearch.timeoutId) {
        clearTimeout(handleSearch.timeoutId);
      }

      // Set a new timeout for debouncing
      handleSearch.timeoutId = setTimeout(() => {
        const searchLang = checkSearchLang(word, locale);

        const queries = [
          {
            indexName: getIndexName(searchLang),
            query: word,
            filters: `stock_region_ids.${regionId || "1101"} > 0`,
          },
          { indexName: getIndexName(searchLang, "suggestions"), query: word },
        ];

        searchClient.multipleQueries(queries).then(({ results }) => {
          let products = results?.[0]?.hits;
          setProducts(products || []);
          if (!isFromHover) {
            setSuggestions(results?.[1]?.hits || []);
          }
        });
      }, DEBOUNCE_DELAY);
    },
    [locale, regionId]
  );

  const setInputValueHandler = (value) => {
    setInputValue(value);
    if (value.length >= 3) {
      handleSearch(value);
    } else {
      setProducts([]);
      setSuggestions([]);
    }
  };

  return (
    <InstantSearch searchClient={searchClient}>
      <SearchBox
        locale={locale}
        handleInputFocus={handleInputFocus}
        openSearchPopupTerms={openSearchPopupTerms}
        openSearchTermsRef={openSearchTermsRef}
        setSavedSearchWords={setSavedSearchWords}
        savedSearchWords={savedSearchWords}
        redirectToSearch={redirectToSearch}
        setShowSearchBoxModal={setShowSearchBoxModal}
        showSearchBoxModal={showSearchBoxModal}
        setOpenSearchPopupTerms={setOpenSearchPopupTerms}
        setInputValueHandler={setInputValueHandler}
        inputValue={inputValue}
        products={products}
        suggestions={suggestions}
        handleSearch={handleSearch}
      />
    </InstantSearch>
  );
}

export default AlgoliaSearch;
