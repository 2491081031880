import { highlightText } from "@/utils/global";
import Link from "next/link";
import React from "react";

export default function SuggestedCategories({
  title,
  data,
  clearSearch,
  searchTerm,
}) {
  return (
    <div className="mb-3">
      <h3 className="mb-2 text-sm font-semibold text-red">{title || ""}</h3>{" "}
      <div className="grid grid-cols-1 max-[570px]:grid-cols-3">
        {data?.length > 0 &&
          data?.map((cat) => (
            <Link
              dangerouslySetInnerHTML={{
                __html: highlightText(searchTerm, cat?.name) || "",
              }}
              key={cat.id}
              href={`/${cat?.url_key}`}
              onClick={clearSearch}
              className="rounded-md px-2 py-1.5 hover:bg-slate-200 text-start align-items-center transition text-xs"
            />
          ))}
      </div>
    </div>
  );
}
